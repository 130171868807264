<template>
  <div>
    <phones-header></phones-header>
    <header-component></header-component>
    <banner-component-chimney-relining></banner-component-chimney-relining>
    <chimney-relining-and-liner-installation></chimney-relining-and-liner-installation>
    <why-might-flue-liner></why-might-flue-liner>
    <flue-liner-option></flue-liner-option>
    <advanced-chimney-techniques-can-handle-your-flue-restoration-and-rebuild></advanced-chimney-techniques-can-handle-your-flue-restoration-and-rebuild>
    <we-also-work></we-also-work>
    <contact-us></contact-us>
    <all-number-component></all-number-component>
    <call-footer></call-footer>
    <footer-component></footer-component>
  </div>
</template>

<script>
import PhoneNumberHeader from '../components/PhoneNumberHeader'
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import BannerComponentChimneyRelining from '../components/BannerComponentChimneyRelining';
import ContactUsSection from '../components/ContactUsSection';
import CallUsFooter from '../components/CallUsFooter'
import ContactAdvancedChimney from '../components/ContactAdvancedChimney'
import ChimneyReliningAndLinerInstallation from '../components/ChimneyReliningAndLinerInstallation'
import WhyMightFlueLiner from '../components/WhyMightFlueLiner'
import WeAlsoWork from '../components/WeAlsoWork'
import FlueLinerOptions from '../components/FlueLinerOptions'
import AdvancedChimneyTechniquesCanHandleYourFlueRestorationAndRebuild
 from '../components/AdvancedChimneyTechniquesCanHandleYourFlueRestorationAndRebuild'

export default {
  name: 'ChimneyRelining',
  components: {
    'phones-header': PhoneNumberHeader,
    'header-component': HeaderComponent,
    'footer-component': FooterComponent,
    'banner-component-chimney-relining': BannerComponentChimneyRelining,
    'contact-us': ContactUsSection,
    'call-footer': CallUsFooter,
    'all-number-component': ContactAdvancedChimney,
    'chimney-relining-and-liner-installation': ChimneyReliningAndLinerInstallation,
    'why-might-flue-liner': WhyMightFlueLiner,
    'we-also-work': WeAlsoWork,
    'flue-liner-option': FlueLinerOptions,
     'advanced-chimney-techniques-can-handle-your-flue-restoration-and-rebuild': AdvancedChimneyTechniquesCanHandleYourFlueRestorationAndRebuild,
  },
}
</script>
