
<template>
  <carousel :per-page="6" v-bind:drag="false" :arrows="true" :dots="true" v-bind:autoplay="true" v-bind:loop="true">
    <slide>
       <img src="media/home/cer1.png" alt="Chania">
    </slide>
    <slide>
       <img src="media/home/cer2.png" alt="Chania">
    </slide>

	<slide>
       <img src="media/home/cer3.png" alt="Chania">
    </slide>
    <slide>
       <img src="media/home/cer4.png" alt="Chania">
    </slide>

	<slide>
       <img src="media/home/cer5.png" alt="Chania">
    </slide>
    <slide>
       <img src="media/home/cer1.png" alt="Chania">
    </slide>

	<slide>
       <img src="media/home/cer2.png" alt="Chania">
    </slide>
    <slide>
       <img src="media/home/cer3.png" alt="Chania">
    </slide>
  </carousel>
</template>


<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide
  },

  data() {
    return {
      slides:
        [
          {
            title: 'Slide #1',
            content: 'Slide content.'
          }
        ]
		
    }
  }
}
</script>


