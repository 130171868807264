<template>
  <div>
    <phones-header></phones-header>
    <header-component></header-component>
    <home-banner></home-banner>
    <core-services></core-services>
    <text-area></text-area>
    <customer-offering></customer-offering>
    <basic-inspections></basic-inspections>
    <service-area></service-area>
    <certificates-pane></certificates-pane>
    <all-number-component></all-number-component>
    <call-footer></call-footer>
    <footer-component></footer-component>
  </div>
</template>

<script>
import PhoneNumberHeader from '../components/PhoneNumberHeader'
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import BasicInspections from '../components/BasicInspections'
import CallUsFooter from '../components/CallUsFooter'
import Certificates from '../components/Certificates'
import CustomerOffering from '../components/CustomerOffering'
import HomeBanner from '../components/HomeBanner'
import MainTextArea from '../components/MainTextArea'
import ServiceArea from '../components/ServiceArea'
import CoreServices from '../components/CoreServices'
import ContactAdvancedChimney from '../components/ContactAdvancedChimney'

export default {
  name: 'Home',
  components: {
    'header-component': HeaderComponent,
    'footer-component': FooterComponent,
    'phones-header': PhoneNumberHeader,
    'basic-inspections': BasicInspections,
    'call-footer': CallUsFooter,
    'certificates-pane': Certificates,
    'customer-offering': CustomerOffering,
    'core-services':CoreServices,
    'home-banner': HomeBanner,
    'text-area': MainTextArea,
    'service-area': ServiceArea,
    'all-number-component': ContactAdvancedChimney
  },
}
</script>
