<template>
  <div>
    <phones-header></phones-header>
    <header-component></header-component>
    <banner-component-showroom></banner-component-showroom>
    <visit-showroom-section></visit-showroom-section>
    <vedio-section></vedio-section>
    <best-brand-showroom></best-brand-showroom>
    <contact-us></contact-us>
    <all-number-component></all-number-component>
    <call-footer></call-footer>
    <footer-component></footer-component>
  </div>
</template>

<script>
import PhoneNumberHeader from '../components/PhoneNumberHeader'
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import ContactUsSection from '../components/ContactUsSection';
import CallUsFooter from '../components/CallUsFooter'
import ContactAdvancedChimney from '../components/ContactAdvancedChimney'
import BannerComponentShowroom from '../components/BannerComponentShowroom'
import VisitShowroomSection from '../components/VisitShowroomSection'
import VedioSection from '../components/VedioSection'
import BestBrandShowroom from '../components/BestBrandShowroom'


export default {
  name: 'OurShowroom',
  components: {
    'phones-header': PhoneNumberHeader,
    'header-component': HeaderComponent,
    'footer-component': FooterComponent,
    'contact-us': ContactUsSection,
    'call-footer': CallUsFooter,
    'all-number-component': ContactAdvancedChimney,
    'banner-component-showroom': BannerComponentShowroom,
    'visit-showroom-section': VisitShowroomSection,
    'vedio-section': VedioSection,
    'best-brand-showroom': BestBrandShowroom,
    },
}
</script>
