<template>
  <div>
    <phones-header></phones-header>
    <header-component></header-component>
    <banner-component-gas-fireplaceappliences></banner-component-gas-fireplaceappliences>
    <gas-burning-fireplaces-appliances-sec></gas-burning-fireplaces-appliances-sec>
    <gas-burning-fireplaces-appliances-border-sec></gas-burning-fireplaces-appliances-border-sec>>
    <contact-us></contact-us>
    <all-number-component></all-number-component>
    <call-footer></call-footer>
    <footer-component></footer-component>
  </div>
</template>

<script>
import PhoneNumberHeader from '../components/PhoneNumberHeader'
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import ContactUsSection from '../components/ContactUsSection';
import CallUsFooter from '../components/CallUsFooter'
import ContactAdvancedChimney from '../components/ContactAdvancedChimney'
import BannerComponentGasFireplaceAppliences from '../components/BannerComponentGasFireplaceAppliences'
import GasBurningFireplacesAppliancesSec from '../components/GasBurningFireplacesAppliancesSec'
import GasBurningFireplacesAppliancesborderSec from '../components/GasBurningFireplacesAppliancesborderSec'



export default {
  name: 'GasBurningFireplacesAppliances',
  components: {
    'phones-header': PhoneNumberHeader,
    'header-component': HeaderComponent,
    'footer-component': FooterComponent,
    'contact-us': ContactUsSection,
    'call-footer': CallUsFooter,
    'all-number-component': ContactAdvancedChimney,
    'banner-component-gas-fireplaceappliences': BannerComponentGasFireplaceAppliences,
    'gas-burning-fireplaces-appliances-sec': GasBurningFireplacesAppliancesSec,
    'gas-burning-fireplaces-appliances-border-sec': GasBurningFireplacesAppliancesborderSec,
    },
}
</script>
