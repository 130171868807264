<template>
  <div>
    <phones-header></phones-header>
    <header-component></header-component>
    <banner-component-about></banner-component-about>
    <about-advanced-chimney-techniques></about-advanced-chimney-techniques>
    <certification-and-skill></certification-and-skill>
    <contact-us></contact-us>
    <all-number-component></all-number-component>
    <call-footer></call-footer>
    <footer-component></footer-component>
  </div>
</template>

<script>
import PhoneNumberHeader from '../components/PhoneNumberHeader'
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import BannerComponentAbout from '../components/BannerComponentAbout';
import AboutAdvancedChimneyTechniques from '../components/AboutAdvancedChimneyTechniques';
import ContactUsSection from '../components/ContactUsSection';
import CertificationAndSkill from '../components/CertificationAndSkill';
import CallUsFooter from '../components/CallUsFooter'
import ContactAdvancedChimney from '../components/ContactAdvancedChimney'

export default {
  name: 'About',
  components: {
    'phones-header': PhoneNumberHeader,
    'header-component': HeaderComponent,
    'footer-component': FooterComponent,
    'banner-component-about': BannerComponentAbout,
    'about-advanced-chimney-techniques': AboutAdvancedChimneyTechniques,
    'contact-us': ContactUsSection,
    'certification-and-skill': CertificationAndSkill,
    'call-footer': CallUsFooter,
    'all-number-component': ContactAdvancedChimney
  },
}
</script>
