<template>
  <div>
    <phones-header></phones-header>
    <header-component></header-component>
    <banner-component-fireplace-chimney-installation></banner-component-fireplace-chimney-installation>
    <expert-wood-and-gas-burning-installations></expert-wood-and-gas-burning-installations>
    <contact-us></contact-us>
    <all-number-component></all-number-component>
    <call-footer></call-footer>
    <footer-component></footer-component>
  </div>
</template>

<script>
import PhoneNumberHeader from '../components/PhoneNumberHeader'
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import ContactUsSection from '../components/ContactUsSection';
import CallUsFooter from '../components/CallUsFooter'
import ContactAdvancedChimney from '../components/ContactAdvancedChimney'
import BannerComponentFireplaceChimneyInstallation from '../components/BannerComponentFireplaceChimneyInstallation'
import ExpertWoodAndGasBurningInstallations from '../components/ExpertWoodAndGasBurningInstallations'


export default {
  name: 'FireplaceChimneyInstallation',
  components: {
    'phones-header': PhoneNumberHeader,
    'header-component': HeaderComponent,
    'footer-component': FooterComponent,
    'contact-us': ContactUsSection,
    'call-footer': CallUsFooter,
    'all-number-component': ContactAdvancedChimney,
    'banner-component-fireplace-chimney-installation': BannerComponentFireplaceChimneyInstallation,
    'expert-wood-and-gas-burning-installations': ExpertWoodAndGasBurningInstallations,
    },
}
</script>
