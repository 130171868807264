<template>
  <div>
    <phones-header></phones-header>
    <header-component></header-component>
    <banner-component-service-area></banner-component-service-area>
    <our-service-area></our-service-area>
    <contact-us></contact-us>
    <all-number-component></all-number-component>
    <call-footer></call-footer>
    <footer-component></footer-component>
  </div>
</template>

<script>
import PhoneNumberHeader from '../components/PhoneNumberHeader'
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import BannerComponentServiceArea from '../components/BannerComponentServiceArea';
import OurServiceArea from '../components/OurServiceArea';
import ContactUsSection from '../components/ContactUsSection';
import CallUsFooter from '../components/CallUsFooter'
import ContactAdvancedChimney from '../components/ContactAdvancedChimney'

export default {
  name: 'Service',
  components: {
    'phones-header': PhoneNumberHeader,
    'header-component': HeaderComponent,
    'footer-component': FooterComponent,
    'banner-component-service-area': BannerComponentServiceArea,
    'our-service-area': OurServiceArea,
    'contact-us': ContactUsSection,
    'call-footer': CallUsFooter,
    'all-number-component': ContactAdvancedChimney
  },
}
</script>
