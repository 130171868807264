<template>
  <carousel :per-page="1" :autoplay="true" :loop="true" :mouseDrag="true" >
    <slide>
      <div>
        <img src="media/slider/s1.jpg" alt="Chania" style="width:100%;"/>
        
      </div>
    </slide>
    <slide>
      <img src="media/slider/s2.jpg" alt="Chicago" style="width:100%;" />
    
    </slide>
    <slide>
      <img src="media/slider/s3.jpg" alt="Chicago" style="width:100%;" />
    
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide
  },

  data() {
    return {
    }
  }
}

</script>

<style scoped>

.centered {
  position: absolute;
  top: 73%;
  left: 36%;
  transform: translate(-50%, -50%);
  height: 500px;
}
.VueCarousel-slide{
  height: auto;
}

</style>

