import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home"
import CleaningSweepingChimneys from "../views/CleaningSweepingChimneys"
import About from "../views/About"
import Service from "../views/Service"
import ChimneyInspection from "../views/ChimneyInspection"
import ChimneyRepairsRebuilds from "../views/ChimneyRepairsRebuilds"
import ChimneyRelining from "../views/ChimneyRelining"
import FireplaceChimneyInstallation from "../views/FireplaceChimneyInstallation"
import GasBurningFireplacesAppliances from "../views/GasBurningFireplacesAppliances"
import DryerVentCleaning from "../views/DryerVentCleaning"
import Showroom from "../views/Showroom"
import Contact from "../views/Contact"
import AppointmentTips from "../views/AppointmentTips"
import ChimneyFireplaceServices from "../views/ChimneyFireplaceServices"


import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueRouter, VueAxios, axios)

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/",
            name: "home",
            component: Home,
        },
        {
            path: "/clean-sweep-chimneys",
            name: "clean-sweep-chimney",
            component: CleaningSweepingChimneys
        },
        {
            path: "/about",
            name: "about",
            component: About
        },
        {
            path: "/service",
            name: "service",
            component: Service
        },
        {
            path: "/chimney-inspection",
            name: "chimney-inspection",
            component: ChimneyInspection
        },
        {
            path: "/chimney-repairs-rebuilds",
            name: "chimney-repairs-rebuilds",
            component: ChimneyRepairsRebuilds
        },
        {
            path: "/chimney-relining",
            name: "chimney-relining",
            component: ChimneyRelining
        },
        {
            path: "/fireplace-chimney-installation",
            name: "fireplace-chimney-installation",
            component: FireplaceChimneyInstallation
        },
        {
            path: "/gas-burning-fireplaces-appliances",
            name: "gas-burning-fireplaces-appliances",
            component: GasBurningFireplacesAppliances
        },
        {
            path: "/dryer-vent-cleaning",
            name: "dryer-vent-cleaning",
            component: DryerVentCleaning
        },
        {
            path: "/showroom",
            name: "showroom",
            component: Showroom
        },
        {
            path: "/contact",
            name: "contact",
            component: Contact
        },
        {
            path: "/appointment-tips",
            name: "appointment-tips",
            component: AppointmentTips
        },
        {
            path: "/chimney-fireplace-services",
            name: "chimney-fireplace-services",
            component: ChimneyFireplaceServices
        }
    ]
})

