<template>
  <div>
    <phones-header></phones-header>
    <header-component></header-component>
    <banner-component-appointment></banner-component-appointment>
    <tips-for-scheduling></tips-for-scheduling>
    <appointment-box></appointment-box>
    <contact-us></contact-us>
    <all-number-component></all-number-component>
    <call-footer></call-footer>
    <footer-component></footer-component>
  </div>
</template>

<script>
import PhoneNumberHeader from '../components/PhoneNumberHeader'
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import ContactUsSection from '../components/ContactUsSection';
import CallUsFooter from '../components/CallUsFooter'
import ContactAdvancedChimney from '../components/ContactAdvancedChimney'
import BannerComponentAppointmentTips from '../components/BannerComponentAppointmentTips'
import TipsForScheduling from '../components/TipsForScheduling'
import AppointmentBox from '../components/AppointmentBox'



export default {
  name: 'AppointmentTips',
  components: {
    'phones-header': PhoneNumberHeader,
    'header-component': HeaderComponent,
    'footer-component': FooterComponent,
    'contact-us': ContactUsSection,
    'call-footer': CallUsFooter,
    'all-number-component': ContactAdvancedChimney,
    'banner-component-appointment': BannerComponentAppointmentTips,
    'tips-for-scheduling': TipsForScheduling,
    'appointment-box': AppointmentBox,
    },
}
</script>
