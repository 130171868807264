<template>
  <div>
    <phones-header></phones-header>
    <header-component></header-component>
    <banner-component-dryer-vent-cleaning></banner-component-dryer-vent-cleaning>
    <certified-dryer-vent-cleaning></certified-dryer-vent-cleaning>
    <safe-and-warm-box></safe-and-warm-box>
    <contact-us></contact-us>
    <all-number-component></all-number-component>
    <call-footer></call-footer>
    <footer-component></footer-component>
  </div>
</template>

<script>
import PhoneNumberHeader from '../components/PhoneNumberHeader'
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import ContactUsSection from '../components/ContactUsSection';
import CallUsFooter from '../components/CallUsFooter'
import ContactAdvancedChimney from '../components/ContactAdvancedChimney'
import BannerComponentDryerVentCleaning from '../components/BannerComponentDryerVentCleaning'
import CertifiedDryerVentCleaning from '../components/CertifiedDryerVentCleaning'
import SafeAndWarmBox from '../components/SafeAndWarmBox'


export default {
  name: 'DryerVentCleaning',
  components: {
    'phones-header': PhoneNumberHeader,
    'header-component': HeaderComponent,
    'footer-component': FooterComponent,
    'contact-us': ContactUsSection,
    'call-footer': CallUsFooter,
    'all-number-component': ContactAdvancedChimney,
    'banner-component-dryer-vent-cleaning': BannerComponentDryerVentCleaning,
    'certified-dryer-vent-cleaning': CertifiedDryerVentCleaning,
    'safe-and-warm-box': SafeAndWarmBox,
    },
}
</script>
