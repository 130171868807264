<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import VueTabulator from 'vue-tabulator';
import VModal from 'vue-js-modal'


import Vue from 'vue';

Vue.use(VueTabulator);
Vue.use(VModal);
export default {
  name: 'App',
  
}
</script>

<style>
body {
  background-color: #f0f0f0;
}
#app {
  min-height: 100vh;
}
.bs-example {
    display: flex;
    background: #f8f9fa;
}
.top_button {
    background: #892d2b;
    border: 1px solid #892d2b;
}
.call-button_top {
    margin-top: 2.4%;
}
</style>